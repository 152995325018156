import {onCLS, onFCP, onFID, onLCP, onTTFB} from 'web-vitals';

window.dataLayer = window.dataLayer || [];

function sendVitalsMeasurement({name, delta, id})
{
    window.dataLayer.push({
        'event': name,
        'eventLabel': id,
        'value': Math.round(name === 'CLS' ? delta * 1000 : delta),
        'valueRaw': delta,
    });
}

onFCP(sendVitalsMeasurement);
onCLS(sendVitalsMeasurement);
onFID(sendVitalsMeasurement);
onLCP(sendVitalsMeasurement);
onTTFB(sendVitalsMeasurement);