export class MainNavigation
{
    static bindEvents()
    {
        this.mobileTopMenuButton = document.querySelector('.evt-main-nav-toggle');
        this.menu = document.querySelector('.evt-main-nav');

        if (this.menu instanceof HTMLElement && this.mobileTopMenuButton instanceof HTMLElement) {
            this.mobileTopMenuButton.addEventListener('click', (e) => {
                e.preventDefault();
                this.toggle();
            }, true);

            this.menu.addEventListener('click', this._menuListener, true);
        }
    }

    static isDesktop()
    {
        let btn = document.querySelector('#main-nav--toggle');
        return btn ? getComputedStyle(btn, null).display === 'none' : false;
    }

    static _menuListener(e)
    {
        let toggleLevelBtn = MainNavigation.getClosestElmByClass(e, 'evt-open-submenu');
        if (toggleLevelBtn) {
            let cls = toggleLevelBtn.parentNode.classList;
            if (MainNavigation.isDesktop()) {
                if (cls.contains('visible')) {
                    cls.remove('visible');
                    window.removeEventListener('click', MainNavigation._clickListenerDesktop);
                } else {
                    for (let submenu of document.querySelectorAll('.main-nav--item-wrapper.visible')) {
                        submenu.classList.remove('visible');
                    }
                    cls.add('visible');
                    window.addEventListener('click', MainNavigation._clickListenerDesktop, true);
                }
            } else {
                if (cls.contains('visible-mobile')) {
                    cls.remove('visible-mobile');
                } else {
                    cls.toggle('visible');
                }
            }
        }
    }

    static getClosestElmByClass(e, cls)
    {
        return e.target.classList.contains(cls) ?
            e.target :
            (e.target.closest('.' + cls) ? e.target.closest('.' + cls) : false);
    }

    static toggle()
    {
        if (!this.menu.classList.contains('open')) {
            this._open();
        } else {
            this._close();
        }
    }

    static _open()
    {
        this.menu.classList.add('open');
        this.menu.focus();
        this.mobileTopMenuButton.classList.add('open');
        window.addEventListener('click', this._clickListener, true);
    }

    static _close()
    {
        this.mobileTopMenuButton.classList.remove('open');
        this.menu.classList.remove('open');
        this.menu.scrollTo(0, 0);

        window.removeEventListener('click', this._clickListener);
        this.menu.removeEventListener('click', this._menuListener);
    }

    static _clickListener(e)
    {
        if (e.target.closest('.main-nav--content-wrapper') === null && !e.target.closest('#main-nav--toggle')) {
            MainNavigation._close();
        }
    }

    static _clickListenerDesktop(e)
    {
        if (e.target.closest('.main-nav--content-wrapper') === null) {
            for (let submenu of document.querySelectorAll('.main-nav--item-wrapper.visible')) {
                submenu.classList.remove('visible');
            }
            window.removeEventListener('click', MainNavigation._clickListenerDesktop);
        }
    }
}