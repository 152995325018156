export class LazyAjaxContent
{
    static initObserver(lazyContent)
    {
        if ('IntersectionObserver' in window &&
            'isIntersecting' in window.IntersectionObserverEntry.prototype) {

            let config = {
                root: null, // relative to document viewport
                rootMargin: '0px 0px 50px 0px', // margin around root. Values are similar to css property. Unitless values not allowed
                threshold: 0 // visible amount of item shown in relation to root
            };

            let onChange = (changes, observer) => {
                for (let change of changes) {
                    if (change.isIntersecting) {
                        observer.unobserve(change.target);
                        this.loadContent(change.target);
                    }
                }
            };

            const observer = new IntersectionObserver(onChange, config);
            for (let content of lazyContent) {
                observer.observe(content);
            }
        } else {
            for (let content of lazyContent) {
                LazyAjaxContent.loadContent(content);
            }
        }
    }

    static async loadContent(content)
    {
        const response = await fetch(content.getAttribute('data-lazy-content'));
        const json = await response.json();
        const contentFragment = document.createRange().createContextualFragment(json.data.html);
        window.bo.events.bindAll(contentFragment);

        for (let link of contentFragment.querySelectorAll('link')) {
            await LazyAjaxContent.loadStyle(link, content.parentNode);
        }

        // Anchor link scroll fix
        const hash = window.location.hash;
        let scrollNeeded = false;
        if (hash && document.querySelector(`${hash}, [data-lazy-content]`)) {
            scrollNeeded = document.querySelector(`${hash}, [data-lazy-content]`).
                hasAttribute('data-lazy-content');
        }

        content.parentNode.replaceChild(contentFragment, content);

        if (scrollNeeded) {
            window.location.hash = '';
            window.location.hash = hash;
        }

        const toplist = document.querySelector('.evt-toplist');
        if (toplist && toplist.classList.contains('evt-lazy') && !this.toplistIsLoaded) {
            toplist.setAttribute('data-page-id', content.dataset.pageId);
            this.toplistIsLoaded = true;
        }
    }

    static async loadStyle(link, node)
    {
        return new Promise((resolve) => {
            link.onload = resolve;
            node.appendChild(link);

            // Ensure that we resolve eventually in case of a bug or missing onload
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    }
}

