import {LoadMoreButton} from '../Library/LoadMoreButton.js';

export class LoadMoreEvents
{
    static bindAll(parent)
    {
        if (!(parent instanceof HTMLElement)) {
            return;
        }

        // Casino review page: Load more Thread covers
        let loadMoreThreadCoversButton = parent.querySelector('.evt-load-more-thread-covers');
        if (loadMoreThreadCoversButton) {
            let container = parent.querySelector('.thread-grid');
            if (loadMoreThreadCoversButton && loadMoreThreadCoversButton.dataset.id && container) {
                LoadMoreButton.create({
                    'button': loadMoreThreadCoversButton,
                    'url': loadMoreThreadCoversButton.dataset.loadUrl,
                    'container': container,
                    'childSelector': '.thread-cover',
                    'limit': 4,
                    'id': loadMoreThreadCoversButton.dataset.id,
                });
            }
        }

        // Author profile: load more items
        for(let loadMore of parent.querySelectorAll('.evt-author-timeline-load-more')) {
            let container = loadMore.closest('.tab').querySelector('.evt-author-items')
            if (loadMore && loadMore.dataset.user && container) {
                LoadMoreButton.create({
                    'button': loadMore,
                    'url': loadMore.dataset.url,
                    'container': container,
                    'childSelector': '.author-timeline-item',
                    'limit': 5,
                    'id': loadMore.dataset.user,
                });
            }
        }

        // Tips: load more items
        for(let loadMore of parent.querySelectorAll('.evt-tips-timeline-load-more')) {
            let container = parent.querySelector('.tip-container')
            if (loadMore && loadMore.dataset.id && container) {
                LoadMoreButton.create({
                    'button': loadMore,
                    'url': loadMore.dataset.url,
                    'container': container,
                    'childSelector': '.list-card',
                    'limit': 5,
                    'id': loadMore.dataset.id,
                    'requestData': loadMore.dataset.json,
                });
            }
        }
    }
}