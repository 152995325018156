import {Ajax} from '../Library/Ajax.js';
import {Toast} from '../Library/Toast.js';
import {Captcha} from '../Library/Captcha.js';
import {Events} from './Events.js';
import {LoaderElement} from './LoaderElement.js';
import {TagManagerTrigger} from '../Library/TagManagerTrigger.js';

export class Bootstrap
{
    constructor()
    {
        let that = this;

        this.loaderElement = LoaderElement;

        this.toast = new Toast({
            'displayTime': 3000,
            'fadeTime': 700,
        });
        this.captcha = new Captcha({
            'sitekey': window.config.reCaptchaPublicKey
        });
        this.tagManagerTrigger = new TagManagerTrigger();

        this.ajax = new Ajax({
            // AJAX options
            'timeout': 10000,
            'errorFunction': async function (xhr) {
                let errorMessage = xhr.responseText;
                if (xhr.responseText.length === 0 && xhr.readyState === 0 && xhr.status === 0) {
                    errorMessage = window.messages.networkError;
                } else {
                    if (xhr.responseText === 'timeout') {
                        errorMessage = window.messages.timeoutWarning;
                    } else {
                        try {
                            let response = JSON.parse(xhr.responseText);
                            const {modal} = await import('../Library/Modal.js');

                            // On status 418 open modals
                            if (xhr.status === 418) {
                                if (response.data && response.data.url) {
                                    modal.open({
                                        contentAjaxUrl: response.data.url,
                                        onOpen: () => {
                                            // Clear previous failed loader
                                            modal.clearLoader();
                                        }
                                    });
                                    return;
                                }
                            }

                            if (xhr.status === 401) {
                                if (response.data && response.data.url) {
                                    modal.open({
                                        contentAjaxUrl: response.data.url,
                                        modalClass: "login",
                                        onOpen: (openedModal) => {
                                            // Clear previous failed loader
                                            window.bo.events.bindAll(openedModal.content);
                                            modal.clearLoader();
                                        }
                                    });
                                }
                            }

                            errorMessage = response.message;
                        } catch (e) {
                            errorMessage = xhr.status + ' ' + xhr.statusText;
                        }
                    }
                }

                that.toast.error(errorMessage);
            },
            'timeoutFunction': function() {
                that.toast.error(window.messages.timeoutWarning);
            },
        }, {
            // Headers
            'X-CSRF-Token': 'user' in window && 'csrfToken' in window.user
                ? window.user.csrfToken : null,
        });

        this.events = Events;
        return this;
    }

    run()
    {
        this.events.bindAll();
    }
}