export class L10n
{
    static localizeDatetime(elm)
    {
        let format;
        let datetime = elm.getAttribute('datetime');
        if (!datetime) {
            return;
        }
        if (elm.classList.contains('date')) {
            format = {
                year: 'numeric',
                day: 'numeric',
                month: 'long',
            };
        } else if (elm.classList.contains('date-short')) {
            format = {
                year: 'numeric',
                day: 'numeric',
                month: 'numeric',
            };
        } else if (elm.classList.contains('datetime')) {
            format = {
                year: 'numeric',
                day: 'numeric',
                month: 'long',
                hour: 'numeric',
                minute: 'numeric',
            };
        } else if (elm.classList.contains('month')) {
            format = {
                year: 'numeric',
                month: 'long',
            };
        } else if (elm.classList.contains('datetime-short')) {
            format = {
                year: 'numeric',
                day: 'numeric',
                month: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
        } else if (elm.classList.contains('date-raw')) {
            return;
        } else {
            format = {
                year: 'numeric',
                day: 'numeric',
                month: 'long',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            };
        }

        let date = new Date(datetime.replace('+0000', 'Z'));
        elm.textContent = date.toLocaleString(navigator.language, format);
    }

    static localizeNumber(elm, minFractionDigits = 0)
    {
        elm.textContent = parseFloat(elm.textContent).toLocaleString(navigator.language, {
            minimumFractionDigits: minFractionDigits,
        });
    }

    static localizeCurrency(elm, currency = 'eur')
    {
        elm.textContent = parseFloat(elm.textContent).toLocaleString(navigator.language, {
            'style': 'currency',
            'currency': currency,
        });
    }
}