interface DataLayerObj {
    event: string;
    eventLabel: string;
}

declare global {
    interface Window {
        dataLayer: DataLayerObj[];
    }
}

export class TagManagerTrigger
{
    constructor()
    {
        document.body.addEventListener('click', e => {
            let et = e.target as HTMLElement;
            let trigger = et.hasAttribute('data-ga-event') ? et : et.closest('[data-ga-event]');

            if (trigger) {
                this.push({
                    'event': trigger.getAttribute('data-ga-event') || '',
                    'eventLabel': trigger.getAttribute('data-ga-label') || ''
                });
            }
        });
    }

    push(data: DataLayerObj): void
    {
        if ('event' in data) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': data.event,
                'eventLabel': data.eventLabel || ''
            });
        } else {
            console.warn('TagManagerTrigger: Missing eventName!');
        }
    }
}
