export class HeaderSearch
{
    constructor()
    {
        this.searchContainer = document.querySelector('.header-search');
        
        if(!this.searchContainer) {
            console.warn('Header Search container not found!');
            return;
        }

        this.toggleBtn = this.searchContainer.querySelector('.evt-open-search');
        this.closeBtn = this.searchContainer.querySelector('.evt-close-search');
        this.timeOut = false;

        this.isVisible = () => {
            return this.searchContainer.classList.contains('open');
        };

        if (!this.toggleBtn) {
            console.warn('Toplist search: Cant find required elements');
            return;
        }

        this.toggleBtn.addEventListener('click', () => {
            this.toggleSearch();
        });
    }

    static close()
    {
        let searchContainer = document.querySelector('.header-search');
        if (searchContainer.classList.contains('open')) {
            searchContainer.classList.remove('open');
        }
        if (!searchContainer.classList.contains('empty')) {
            searchContainer.classList.add('empty');
        }

        window.removeEventListener('click', HeaderSearch._clickListener, true);
    }

    open()
    {
        if (!this.searchContainer.classList.contains('open')) {
            this.searchContainer.classList.add('open');

            let input = this.searchContainer.querySelector('input');
            input ? input.focus() : false;

            window.addEventListener('click', HeaderSearch._clickListener);
        }
    }

    toggleSearch()
    {
        if (this.isVisible()) {
            HeaderSearch.close();
        } else {
            this.open();
        }
    }

    static _clickListener(e)
    {
        if (e.target.closest('.header-search--wrapper') === null && !e.target.closest('.header-search--toggle')) {
            HeaderSearch.close();
        }
    }
}