export class ShowMore
{
    constructor(elm)
    {
        if (!(elm instanceof HTMLElement)) {
            return;
        }

        let content = elm.querySelector('.show-more-shortcode__content');
        let toggle = elm.querySelector('.evt-toggle-show-more-shortcode');
        if (toggle && content) {
            toggle.addEventListener('click', () => {
                elm.classList.toggle('show-more-shortcode--open');
            });
        }
    }
}
